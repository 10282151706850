import React from "react";

import { RenderModules } from "../utils/renderModules";
import { SEO } from "../components/seo";

export interface PageProps {
  pageContext: {
    main: {
      modules: [];
      slug: {
        current: string;
      };
      title: string;
      showTitle: boolean;
    };
    meta: {};
  };
  path?: string;
  preview?: boolean;
}

const Page = ({ path, pageContext, preview = false }: PageProps) => {
  const {
    main: { modules, slug, title, showTitle },
    meta,
  } = pageContext;

  const url = slug.current === "home" ? "" : path;
  return (
    <div>
      {preview && <div className="bcblue ac cw x p1">This is a Preview</div>}
      <SEO metaInfo={meta} pagePath={url} />
      {showTitle && title && (
        <h1 className="px-4 text-center mt-6 md:mt-10">{title}</h1>
      )}
      <div className="container--m mxa x al">{RenderModules(modules)}</div>
    </div>
  );
};

export default Page;
